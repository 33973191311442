import React from 'react'
import { connect } from 'react-redux'
import TableResult from '../components/TableResult';
import { Loader } from '../components/Loader';
import { CSVLink } from 'react-csv';
import resultToCsv from '../selectors/result-csv';
import { toggleScroll } from '../actions';

class ResultPresenter extends React.Component {
    render() {
        const { isFetching, result, message, scrollEnabled, toggleScroll } = this.props;


        if (isFetching) {
            return (
                <Loader />
            )
        }

        if (result) {
            const items = result;
            return (
                <div>
                    {items ?
                        <div className="box has-text-weight-bold level ">
                            <div className="level-left">
                                <div className="level-item">
                                    {items.length}  {items.length === 1 ? 'row' : 'rows'}
                                </div>
                            </div>
                            {items.length > 0 ?
                                <div className="level-right">
                                    <div className="level-item">

                                        <label class="checkbox">
                                            <input type="checkbox" checked={scrollEnabled} onChange={this.props.toggleScroll} />
                                            Enable Scroll
                                        </label>
                                    </div>

                                    <div className="level-item">
                                        <CSVLink separator={";"} className="button is-link" filename={"result.csv"} data={resultToCsv(items)} ><i className="far fa-file fa-fw"></i>Eksportuj</CSVLink>
                                    </div>
                                </div> : null}

                        </div> : null}
                    <div className="scrollable margin-vertical" style={{ height: scrollEnabled ? '45vh' : 'auto' }}>
                        <TableResult items={items} />
                    </div>

                </div>
            )
        }

        if (message) {
            return (
                <div className="box has-text-danger">
                    {message}
                </div>
            )
        }

        return null;
    }
}

const mapStateToProps = (state, props) => ({
    result: state.sqlEditor.result,
    message: state.sqlEditor.message,
    isFetching: state.sqlEditor.isFetching,
    scrollEnabled: state.sqlEditor.scrollEnabled
});

const mapDispatchToProps = (dispatch) => ({
    toggleScroll: () => dispatch(toggleScroll())
})

export default connect(mapStateToProps,mapDispatchToProps)(ResultPresenter)