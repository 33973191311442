import fetch from 'cross-fetch'
import store from '../store';
import { logout } from "../actions/auth";

export function secureFetch(url, init) {
    return fetch(url, init).then(response => {
        if (response.status === 401) {
            store.dispatch(logout());
            return;
        }
        return response;
    })
}
