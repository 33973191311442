import { RECEIVE_SCHEME, REQUEST_SCHEME, RECEIVE_TABLES, REQUEST_TABLES, SET_SCHEME_FILTER, SET_SELECTED_SCHEME } from "../actions";

const defaultState = {
    isTablesFetching: true,
    isSchemeFetching: true,
    schemas: [],
    tables: [],
    filter: '',
    selectedScheme: ''
}

const sqlEditor = (state = defaultState, action) => {
    switch (action.type) {
        case SET_SCHEME_FILTER:
            return {
                ...state,
                filter: action.filter
            };
        case SET_SELECTED_SCHEME:
            return {
                ...state,
                selectedScheme: action.selectedScheme
            };
        case REQUEST_SCHEME:
            return {
                ...state,
                isSchemeFetching: true
            }
        case RECEIVE_SCHEME:
            return {
                ...state,
                isSchemeFetching: false,
                ...action.scheme
            }
        case REQUEST_TABLES:
            return {
                ...state,
                isTablesFetching: true
            }
        case RECEIVE_TABLES:
            return {
                ...state,
                isTablesFetching: false,
                tables: action.tables,
            }
        case 'LOGOUT':
            return defaultState;
        default:
            return state
    }
}

export default sqlEditor