import React from 'react'
import { logout, refresh } from '../actions/auth';
import { connect } from 'react-redux';

const Nav = (props) => (
    <nav className="navbar  is-fixed-top has-shadow " role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
            <a className="navbar-item title is-4">
                SQL EDITOR
                </a>
        </div>
        <div className="navbar-end">
            <span className="navbar-item">
                {props.database}, {props.user}
            </span>
            <div className="navbar-item">
                <div className="navbar-item">
                    <button onClick={props.refresh} className="button is-info"><span className="icon"><i className="fas fa-sync "></i></span><span>Odśwież</span> </button>
                </div>
                <div className="navbar-item">
                    <button onClick={props.logout} className="button is-primary">Wyloguj</button>
                </div>
            </div>
        </div>
    </nav>
)

const mapStateToProps = (state) => ({
    user: state.auth.user,
    database: state.auth.database
})

const mapDispatchToProps = (dispatch) => ({
    refresh: () => dispatch(refresh()),
    logout: () => dispatch(logout())
});

export default connect(mapStateToProps, mapDispatchToProps)(Nav);
