import React from 'react'

const column = (column) => (
    <th>{column}</th>
)

const cell = (cell) => (
    <td>{cell === null ? '(null)' : cell}</td>
)

const row = (item, index) => (
    <tr key={index}><td>{index + 1}</td>{item.map(x => cell(x.value))}</tr>
)
const TableResult = ({ items }) => {
    if (!items)
        return null;

    if (items.length === 0)
        return "Brak wyników";

    const keyes = items[0].cells.map(x => x.columnName)
    const columns = keyes.map(x => column(x));
    const rows = items.map((x, i) => row(x.cells, i));

    return (
        <div className="panel">
            <table className="table table is-bordered is-striped is-narrow is-fullwidth is-hoverable">
                <thead>
                    <tr>
                        <th>#</th>
                        {columns}
                    </tr>
                </thead>
                <tbody className="no-wrap">
                    {rows}
                </tbody>
            </table>

        </div>

    )
}

export default TableResult