
import { secureFetch } from '../auth/secure-fetch';
import { apiUrl } from '../consts';

export const REQUEST_QUERY = 'REQUEST_QUERY'
export const RECEIVE_QUERY = 'RECEIVE_QUERY'
export const RECEIVE_SCHEME = 'RECEIVE_SCHEME'
export const REQUEST_SCHEME = 'REQUEST_SCHEME'
export const RECEIVE_TABLES = 'RECEIVE_TABLES'
export const REQUEST_TABLES = 'REQUEST_TABLES'
export const SET_SCHEME_FILTER = 'SET_SCHEME_FILTER'
export const SET_SELECTED_SCHEME = 'SELECT_SCHEME'
export const SET_EDITOR_VALUE = 'SET_EDITOR_VALUE'
export const TOGGLE_SCROLL = 'TOGGLE_SCROLL';

export function setEditorValue(value) {
    return {
        type: SET_EDITOR_VALUE,
        value
    }
}

export function setSchemeFilter(filter) {
    return {
        type: SET_SCHEME_FILTER,
        filter
    }
}

export function toggleScroll() {
    return {
        type: TOGGLE_SCROLL
    }
}

function requestQuery(query) {
    return {
        type: REQUEST_QUERY,
        query
    }
}

function receiveQuery(query, json) {
    return {
        type: RECEIVE_QUERY,
        query,
        result: json.result,
        message: json.message
    }
}

function requestScheme() {
    return {
        type: REQUEST_SCHEME,
    }
}

function receiveScheme(scheme) {
    return {
        type: RECEIVE_SCHEME,
        scheme
    }
}

function requestTables() {
    return {
        type: REQUEST_TABLES,
    }
}

function receiveTables(tables) {
    return {
        type: RECEIVE_TABLES,
        tables
    }
}




export function setSelectedScheme(selectedScheme) {
    return {
        type: SET_SELECTED_SCHEME,
        selectedScheme
    }
}

export function executeQuery(query) {
    return (dispatch, getState) => {
        dispatch(requestQuery(query));
        const token = getState().auth.token;
        return secureFetch(`${apiUrl}/api/v1/query`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`

            },
            body: JSON.stringify({
                queryText: query
            })
        })
            .then(response => response.json())
            .then(json => dispatch(receiveQuery(query, json)))
    }
}

export function getScheme() {
    return (dispatch, getState) => {
        dispatch(requestScheme());
        const token = getState().auth.token;
        return secureFetch(`${apiUrl}/api/v1/schema`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => response.json())
            .then(json => dispatch(receiveScheme(json)))
    }
}

export function getTables() {
    return (dispatch, getState) => {
        dispatch(requestTables());
        const token = getState().auth.token;
        return secureFetch(`${apiUrl}/api/v1/schema/tables`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => response.json())
            .then(json => dispatch(receiveTables(json)))
    }
}


