import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import history from "./history.js";
import PrivateRoute from './PrivateRoute';
import EditorPage from '../components/EditorPage';
import SchemePage from '../containers/SchemePage';
import { PublicRoute } from './PublicRoute';
import LoginPage from '../containers/LoginPage';
import ObjectDetailsPage from '../containers/ObjectDetailsPage';
import SchemeDetailsPage from '../containers/SchemeDetailsPage';



const AppRouter = () => (
    <Router history={history}>
        <div>
            <Switch>
                <PublicRoute path="/login" component={LoginPage} exact={true} />
                <PrivateRoute path="/" component={EditorPage} exact={true} />
                <PrivateRoute path="/scheme/:scheme?" component={SchemePage} exact={true} />
                <PrivateRoute path="/scheme/:scheme/4/:table" renderComponent={(props) => <ObjectDetailsPage {...props} objectType={4} />} exact={true} />
                <PrivateRoute path="/scheme/:scheme/5/:table" renderComponent={(props) => <ObjectDetailsPage {...props} objectType={5} />} exact={true} />
                <PrivateRoute path="/scheme/:scheme/6/:table" renderComponent={(props) => <ObjectDetailsPage {...props} objectType={6} />} exact={true} />
                <PrivateRoute path="/scheme/:scheme/:object/:table" component={SchemeDetailsPage} exact={true} />
            </Switch>
        </div>
    </Router>
);

export default AppRouter;
