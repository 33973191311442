
export default (rows) => {
    if (rows.length === 0)
        return;

    const csvData = [];
    let csvRow = [];
    rows[0].cells.forEach(cell => {
        csvRow.push(cell.columnName);
    });
    csvData.push(csvRow);
    rows.forEach((row, i) => {
        csvRow = [];
        row.cells.forEach(cell => {
            csvRow.push(cell.value);
        });
        csvData.push(csvRow);
    })
    return csvData;
};


