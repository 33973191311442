import React from 'react'
import { connect } from 'react-redux'

import CodeMirror from 'codemirror/lib/codemirror';
import 'codemirror/mode/sql/sql';
import 'codemirror/addon/hint/show-hint';
import 'codemirror/addon/search/searchcursor';
import 'codemirror/addon/hint/sql-hint';
import 'codemirror/lib/codemirror.css';
import 'codemirror/addon/hint/show-hint.css';
import { executeQuery, setEditorValue, getTables } from '../actions';
import { selectHints } from '../selectors/tables';
import { Loader } from '../components/Loader';

class Editor extends React.Component {

    componentDidMount() {
        const tables = this.props.tables;
        const hintOptions = selectHints(tables);
        this.editor = CodeMirror.fromTextArea(document.getElementById("text-area"), {
            lineNumbers: true,
            mode: 'text/x-mssql',
            indentWithTabs: true,
            smartIndent: true,
            lineNumbers: true,

            matchBrackets: true,
            autofocus: true,
            extraKeys: { "Ctrl-Space": "autocomplete" },
            hintOptions: {
                tables: hintOptions
            }
        });
        this.editor.doc.setValue(this.props.value)
        this.editor.on('change', (e) => this.props.dispatch(setEditorValue(this.editor.doc.getValue())));
    }



    editor;

    getCursoredQuery() {
        const line = this.editor.doc.getCursor().line;
        let textBeforeCursor = '';
        let i = 0;
        for (i = 0; i <= line; i++) {
            textBeforeCursor += this.editor.doc.getLine(i);
        }
        const selectedIndex = textBeforeCursor.split(';').filter(x => x.trim() !== '').length - 1;
        const queries = this.editor.doc.getValue().split(';').filter(x => x.trim() !== '');
        return  queries[selectedIndex];
    }

    render() {
        let { dispatch } = this.props;


        if (this.props.isFetching)
            return <Loader />

        if (this.editor) {
           
        }


        return (
            <div>
                <form onSubmit={e => {
                    e.preventDefault()
                    const value = this.editor.getSelection() === '' ? this.getCursoredQuery() : this.editor.getSelection();
                    if (!value.trim()) {
                        return;
                    }
                    dispatch(executeQuery(value))
                }}>
                    <div id="text-area-container" className="field">
                        <textarea className="textarea"
                            id="text-area"></textarea>
                    </div>
                    <div className="field has-text-centered  margin-vertical">
                        <button type="submit" className="button is-fullwidth is-primary"><i className="fas fa-play-circle  fa-fw"></i>  Wykonaj </button>
                    </div>
                </form>
            </div>
        )
    }
}

const mapStateToProps = (state, props) => ({
    tables: state.scheme.tables,
    isFetching: state.scheme.isSchemeFetching,
    value: state.sqlEditor.value,
});

export default connect(mapStateToProps)(Editor)

