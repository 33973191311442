import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import Menu from '../components/Menu';
import Nav from '../containers/Nav';
import { Loader } from '../components/Loader';

export const PrivateRoute = ({
    isAuthenticated,
    isFetching,
    component: Component,
    ...rest,
    renderComponent
}) => (
        <Route exact={true} strict={true} {...rest} component={(props) => (
            isAuthenticated ? (
                <div>
                    <Nav />
                    <div className="columns">
                        <div className="column is-2 hero   has-background-white-bis padding menu-section  ">
                            <Menu />
                        </div>
                        <div  style={{position: 'absolute', right: '0', top: '3.25rem'}} className="column is-10 padding ">
                            {!isFetching ? (Component ? <Component {...props} /> : renderComponent(props)) : <Loader />}
                        </div>
                    </div>
                </div>) :
                <Redirect to="/login" />
        )} />
    );


const mapStateToProps = (state) => ({
    isAuthenticated: !!state.auth.token,
    isFetching: state.scheme.isSchemeFetching || state.scheme.isTablesFetching
});

export default connect(mapStateToProps)(PrivateRoute);
