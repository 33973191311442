import React from 'react';
import { connect } from 'react-redux';
import { getDatabases, login } from '../actions/auth';
import { Route, Redirect } from 'react-router-dom';
import { Loader } from '../components/Loader';

class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            login: '',
            password: '',
            databaseName: ''
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.props.getDatabases();
    }

    handleSubmit(event) {
        event.preventDefault();
        const { login, password, databaseName } = this.state;
        this.props.login(databaseName, login, password);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    render() {

        const { databases, isAuthenticated, invalid } = this.props;
        if (isAuthenticated)
            return <Redirect to='/' />

        if (!databases)
            return (<Loader />)


        return (
            <div className="hero-body">
                <div className="container has-text-centered">
                    <div className="column is-4 is-offset-4">
                        <h3 className="title ">Logowanie</h3>
                        <p className="subtitle has-text-grey">Wybierz baze danych i zaloguj się.</p>
                        <div className="box">
                            <form onSubmit={this.handleSubmit}>
                                {invalid ? <span className="has-text-danger">Nieprawidłowe dane logowania.</span> : null}
                                <div className="field">
                                    <div className="control">
                                        <div className="select  is-large is-fullwidth">
                                            <select name="databaseName" value={this.state.databaseName} onChange={this.handleInputChange} >
                                                <option>Wybierz bazę</option>
                                                {databases.map(x => (<option key={x.name}>{x.name}</option>))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="field">
                                    <div className="control">
                                        <input className="input is-large" type="text" placeholder="Login" name="login" value={this.state.login} onChange={this.handleInputChange} />
                                    </div>
                                </div>

                                <div className="field">
                                    <div className="control">
                                        <input className="input is-large" type="password" name="password" placeholder="Hasło" value={this.state.password} onChange={this.handleInputChange} />
                                    </div>
                                </div>
                                <button type="submit" className={`button is-block is-primary is-large is-fullwidth ${this.props.isFetching ? 'is-loading' : ''} `}>Login</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, props) => ({
    databases: state.auth.databases,
    isAuthenticated: !!state.auth.token,
    invalid: state.auth.invalid,
    isFetching: state.auth.isFetching

});

const mapDispatchToProps = (dispatch) => ({
    getDatabases: () => dispatch(getDatabases()),
    login: (databaseName, userLogin, password) => dispatch(login(databaseName, userLogin, password))

});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
