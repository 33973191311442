import './styles/styles.scss'
import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import store from './store';
import AppRouter from './routers/AppRouter';
import { getToken } from './auth/token-storage';
import { receiveToken, logout } from './actions/auth';


const token = getToken();
if (token) {
    store.dispatch(receiveToken(token));
}

render(
    <Provider store={store}>
        <AppRouter />
    </Provider>,
    document.getElementById('root')
)