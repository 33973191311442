import React from 'react'
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom'
import { selectSchemeNames } from '../selectors/scheme';

const Menu = ({ schemaNames }) => (
    <aside className="menu ">
        <ul className="menu-list">
            <li>
                <NavLink to="/" exact activeClassName="is-active" ><i className="fas fa-terminal fa-lg fa-fw"></i> Edytor</NavLink>
            </li>
            <li>
                <NavLink exact to="/scheme" activeClassName="is-active" ><i className="fas fa-database fa-lg fa-fw"></i> Schemat</NavLink>
            </li>
        </ul>
    </aside>
)
const mapStateToProps = (state) => ({
    schemaNames: selectSchemeNames(state.scheme.schemas)
});

export default connect(mapStateToProps)(Menu);
