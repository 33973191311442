import React from 'react'


const DataTable = ({ items, rowTemplate, columnsTemplate }) => {
    if (!items)
        return null;

    if(items.length === 0)
        return null;

    const rows = items.map(x => rowTemplate(x));

    return (
        <div>
            <table className="table 
            table  is-striped is-fullwidth is-hoverable">
                <thead>
                
                        {columnsTemplate}
                   
                </thead>
                <tbody className="no-wrap">
                    {rows}
                </tbody>
            </table>
        </div>
    )
}

export default DataTable