import { REQUEST_DETAILS, RECEIVE_DETAILS } from "../actions/scheme-details";

const defaultState = {
    isFetching: true,
    details: null,
}

const schemeDetails = (state = defaultState, action) => {
    switch (action.type) {
        case REQUEST_DETAILS:
            return {
                ...state,
                isFetching: true
            }
        case RECEIVE_DETAILS:
            return {
                ...state,
                isFetching: false,
                details: action.details
            }
        case 'LOGOUT':
            return defaultState;
        default:
            return state
    }
}

export default schemeDetails