import { combineReducers } from 'redux'
import sqlEditor from './sql-editor'
import auth from './auth'
import scheme from './scheme'
import schemeDetails from './scheme-details'


export default combineReducers({
    sqlEditor,
    auth,
    scheme,
    schemeDetails
})