import React from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { Loader } from '../components/Loader';
import { fetchDetails } from '../actions/scheme-details';
import DataTable from '../components/DataTable';
import ObjectScheme from '../components/Object';
import { ObjectTypes } from '../common/consts';

const constraint = (constraintType) => {
    switch (constraintType) {
        case ConstraintType.ForeignKey:
            return <i key={constraintType} className="fas fa-key fa-fw"> </i>
        case ConstraintType.PrimaryKey:
            return <i key={constraintType} className="fas fa-key fa-fw  has-text-primary"> </i>
        default:
            return null;
    }
}


const ConstraintType = {
    Unknown: 0,
    PrimaryKey: 1,
    ForeignKey: 2
}


class SchemeDetailsPage extends React.Component {
    constructor(props) {
        super(props);
    }



    scheme;
    table;
    componentDidMount() {
        const { scheme, table, object } = this.props.match.params;
        this.scheme = scheme;
        this.table = table;
        this.props.fetchSchemeDetails(scheme, table, object);
    }

    render() {
        if (this.props.isFetching)
            return <Loader />

        return (
            <div >

                <div className="column is-12">
                    <nav className="breadcrumb has-arrow-separator" aria-label="breadcrumbs">
                        <ul>
                            <li><NavLink to="/scheme">Schemat</NavLink></li>
                            <li><NavLink to={`/scheme/${this.scheme}`}>{this.scheme}</NavLink></li>
                            <li className="is-active"><a href="#">{this.props.details.table.name}</a></li>
                        </ul>
                    </nav>
                </div>
                <section className="hero is-primary">
                    <div className="hero-body">
                        <div className="container">
                            <h1 className="title">
                                {this.props.details.table.name}
                            </h1>

                        </div>
                    </div>
                </section>
                <div className="container">

                    <div className="box margin-vertical">
                        <h5 className="title is-4">Kolumny</h5>

                        <DataTable items={this.props.details.table.columns} columnsTemplate={(
                            <tr>
                                <th>Kolumna</th>
                                <th>Typ</th>
                                <th>Długość</th>
                                <th>Precyzja</th>
                                <th>Skala</th>
                                <th>Nullable</th>
                            </tr>
                        )}
                            rowTemplate={(item) => (
                                <tr key={item.name}>
                                    <td> {item.name} {item.constraintTypes.map(x => constraint(x))} </td>
                                    <td>{item.dataType}</td>
                                    <td>{item.dataLength}</td>
                                    <td>{item.dataPrecision}</td>
                                    <td>{item.dataScale}</td>
                                    <td>{item.nullable ? "Yes" : "No"}</td>
                                </tr>
                            )}
                        />
                    </div>
                    <div className="box">
                        <h5 className="title is-4">Indeksy</h5>
                        {this.props.details.indexes.length > 0 ?

                            <DataTable items={this.props.details.indexes} columnsTemplate={(
                                <tr>
                                    <th>Nazwa</th>
                                    <th>Typ</th>
                                    <th>Unikalność</th>
                                    <th>Kolumny</th>
                                </tr>
                            )}
                                rowTemplate={(item) => (
                                    <tr key={item.name}>
                                        <td>{item.name}</td>
                                        <td>{item.type}</td>
                                        <td>{item.uniqueness}</td>
                                        <td>{item.columns}</td>
                                    </tr>
                                )}
                            /> : <p>Brak</p>}
                    </div>
                    <div className="box">
                        <h5 className="title is-4">Wyzwalacze</h5>
                        {this.props.details.triggers.length > 0 ?

                            <DataTable items={this.props.details.triggers} columnsTemplate={(
                                <tr>
                                    <th>Nazwa</th>
                                    <th>Typ</th>
                                    <th>Kod</th>
                                </tr>
                            )}
                                rowTemplate={(item) => (
                                    <tr key={item.name}>
                                        <td>{item.name}</td>
                                        <td>{item.type}</td>
                                        <td><pre>{item.body}</pre></td>
                                    </tr>
                                )}
                            /> : <p>Brak</p>}
                    </div>

                    <div className="box">
                        <h5 className="title is-4">Ograniczenia</h5>
                        {this.props.details.triggers.length > 0 ?

                            <DataTable items={this.props.details.constraints} columnsTemplate={(
                                <tr >
                                    <th>Nazwa</th>
                                    <th>Typ</th>
                                    <th>Warunek</th>
                                    <th>Na usunięcie</th>

                                </tr>
                            )}
                                rowTemplate={(item) => (
                                    <tr key={item.name}>
                                        <td>{item.name}</td>
                                        <td>{item.type}</td>
                                        <td>{item.condition}</td>
                                        <td>{item.onDelete}</td>

                                    </tr>
                                )}
                            /> : <p>Brak</p>}
                    </div>
                    <div className="box">
                        <h5 className="title is-4">Powiązane tabele</h5>
                        {this.props.details.referencedTables.length > 0 ?

                            this.props.details.referencedTables.map(x => <ObjectScheme object={{ ...x, type: ObjectTypes.Table, typeName: 'TABLE' }} scheme={this.scheme} />)
                            : <p>Brak</p>}
                        <div className="is-clearfix"></div>
                    </div>
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state, props) => ({
    details: state.schemeDetails.details,
    isFetching: state.schemeDetails.isFetching
});

const mapDispatchToProps = (dispatch, props) => ({
    fetchSchemeDetails: (scheme, table, object) => dispatch(fetchDetails(scheme, table, object)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SchemeDetailsPage)