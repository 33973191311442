import React from 'react'
import Editor from '../containers/Editor';
import ResultPresenter from '../containers/ResultPresenter';


class EditorPage extends React.Component {
    render() {
        return (
            <div>
                <Editor />

                <ResultPresenter />

            </div>
        )
    }
}

export default EditorPage