import React from 'react'
import { connect } from 'react-redux'
import { Loader } from '../components/Loader';
import { fetchDetails } from '../actions/scheme-details';
import { ObjectTypes } from '../common/consts';
import { NavLink } from 'react-router-dom'



class ObjectDetailsPage extends React.Component {
    constructor(props) {
        super(props);
    }



    scheme;
    table;
    componentDidMount() {
        const { scheme, table, object } = this.props.match.params;
        this.scheme = scheme;
        this.table = table;
        console.log(this.props.match)
        this.props.fetchSchemeDetails(scheme, table, this.props.objectType);
    }

    render() {
        if (this.props.isFetching)
            return <Loader />

        return (
            <div >
                <div className="column is-12">
                    <nav className="breadcrumb has-arrow-separator" aria-label="breadcrumbs">
                        <ul>
                            <li><NavLink to="/scheme">Schemat</NavLink></li>
                            <li><NavLink to={`/scheme/${this.scheme}`}>{this.scheme}</NavLink></li>
                            <li className="is-active"><a href="#">{this.props.details.name}</a></li>
                        </ul>
                    </nav>
                </div>
                <section className="hero is-primary">
                    <div className="hero-body">
                        <div className="container">
                            <h1 className="title">
                                {this.props.details.name}
                            </h1>

                        </div>
                    </div>
                </section>
                <div className="container margin-vertical">
                    <pre>
                        {this.props.details.body}
                    </pre>
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state, props) => ({
    details: state.schemeDetails.details,
    isFetching: state.schemeDetails.isFetching
});

const mapDispatchToProps = (dispatch, props) => ({
    fetchSchemeDetails: (scheme, table, objectType) => dispatch(fetchDetails(scheme, table, objectType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ObjectDetailsPage)