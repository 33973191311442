
export default (schemas, scheme) => {
  return schemas.filter((schema) => scheme === '' || schema.name.toLowerCase() === scheme.toLowerCase());
};


export const selectSchemeNames = (schemas) => {
  if (!schemas)
    return [];
  return schemas.map(x => x.name);
}