
import { secureFetch } from '../auth/secure-fetch';
import { apiUrl } from '../consts';

export const REQUEST_DETAILS = 'REQUEST_DETAILS'
export const RECEIVE_DETAILS = 'RECEIVE_DETAILS'


function requestDetails(query) {
    return {
        type: REQUEST_DETAILS,
        query
    }
}

function receiveDetails(data) {
    return {
        type: RECEIVE_DETAILS,
        details: data
    }
}


export function fetchDetails(scheme, table, objectType) {
    return (dispatch, getState) => {
        dispatch(requestDetails());
        const token = getState().auth.token;
        return secureFetch(`${apiUrl}/api/v1/schema/details/${scheme}/${objectType}/${table}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => response.json())
            .then(json => dispatch(receiveDetails(json)))
    }
}


