import React from 'react'
import { NavLink } from 'react-router-dom';
import { ObjectTypes } from '../common/consts';

const ObjectScheme = ({ object, scheme }) => {

    const icon = (type) => {
        switch (type) {
            case ObjectTypes.Table:
                return <i className="fas fa-table fa-2x  has-text-info"></i>;
            case ObjectTypes.Index:
                return <i className="fas fa-indent fa-2x has-text-info"></i>;
            case ObjectTypes.Trigger:
                return <i className="fas fa-cog fa-2x  has-text-info"></i>;
            case ObjectTypes.View:
                return <i className="fas fa-eye fa-2x  has-text-info"></i>;
            case ObjectTypes.Procedure:
                return <i className="fas fa-cog fa-2x  has-text-info"></i>;
            default:
                return <i className="fas fa-th-large  fa-2x  has-text-info"></i>
        }
    }

    return (
        <NavLink to={object.type !== ObjectTypes.Unknown ?  `/scheme/${scheme}/${object.type}/${object.name}` : `#`}>
            <div className="column is-3 is-pulled-left "  >
                <div className="box object-box">

                    <div className="media">
                        <div className="media-left">
                            <figure>
                                {icon(object.type)}
                            </figure>
                        </div>
                        <div className="media-content">
                            <p className="title is-6"> {object.name}</p>
                            <p className="subtitle is-7 ">Type: <strong>{object.typeName}</strong></p>
                        </div>
                    </div>
                </div>

            </div>
        </NavLink >
    )
}

export default ObjectScheme;