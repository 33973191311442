import React from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import ObjectScheme from '../components/Object';
import { getScheme, setSchemeFilter, setSelectedScheme } from '../actions';
import { Loader } from '../components/Loader';
import selectTables from '../selectors/tables'
import selectScheme from '../selectors/scheme'
import { ObjectTypes } from '../common/consts';


class SchemePage extends React.Component {
    constructor(props) {
        super(props);
        this.onTextChange = this.onTextChange.bind(this);
    }
    componentDidMount() {
        const { scheme } = this.props.match.params;
        this.props.setSelectedScheme(scheme || '');

    }

    mapObjectTypeToName(objectType) {
        switch (objectType) {
            case ObjectTypes.Table:
                return "Tables";
            case ObjectTypes.View:
                return "Views";
            case ObjectTypes.Trigger:
                return "Triggers";
            case ObjectTypes.Index:
                return "Indexes";
            case ObjectTypes.Procedure:
                return "Procedures";
            case ObjectTypes.Function:
                return "Functions";
            case ObjectTypes.Unknown:
                return "Other";
        }
    }

    onTextChange(e) {
        this.props.setSchemeFilter(e.target.value);
    };

    render() {
        if (this.props.isFetching)
            return <Loader />

        if (!this.props.schemas)
            return null;

        const schemas = this.props.schemas.map(x => (

            <div key={x.name}>
                {!this.props.selectedScheme ?
                    <div className="column is-12">
                        <h1 className="title is-4"><NavLink to={`/scheme/${x.name}`}>{x.name}</NavLink></h1>
                    </div> : null}
                {Object.keys(ObjectTypes).map(o => {

                    const objects = x.objects.filter(x => x.type === ObjectTypes[o])
                                             .sort((a, b) => {
                                                if(a.name < b.name) { return -1; }
                                                if(a.name > b.name) { return 1; }
                                                return 0;
                                             })
                                             .map((t, i) => (<ObjectScheme key={t.fullName} scheme={x.name} typeName={t.typeName} object={t} isAncestor={i % 4 === 0} />))
                    if (objects.length > 0)
                        return (
                            <div key={o}>
                                <div className="column is-12">
                                    <h2 className="title is-5">{this.mapObjectTypeToName(ObjectTypes[o])}</h2>
                                </div>
                                {objects}
                                <div className="is-clearfix"></div>
                            </div>
                        )
                })}

            </div>
        ))
        return (
            <div >
                <div className="column is-12">
                    {this.props.selectedScheme !== '' ?
                        <nav className="breadcrumb has-arrow-separator" aria-label="breadcrumbs">
                            <ul>
                                <li><NavLink to="/scheme">Schemat</NavLink></li>
                                <li className="is-active"><a href="#">{this.props.selectedScheme}</a></li>
                            </ul>
                        </nav> : null}
                    <div className="control has-icons-left">
                        <input className="input is-medium" type="text" value={this.props.filter}
                            onChange={this.onTextChange} placeholder="Szukaj" />
                        <span className="icon is-left">
                            <i className="fas fa-search"></i>
                        </span>
                    </div>
                </div>
                {schemas}
            </div>
        )
    }
}

const mapStateToProps = (state, props) => ({
    schemas: selectScheme(selectTables(state.scheme.schemas, state.scheme.filter), state.scheme.selectedScheme),
    isFetching: state.scheme.isSchemeFetching,
    filter: state.scheme.filter,
    selectedScheme: state.scheme.selectedScheme,
});

const mapDispatchToProps = (dispatch, props) => ({
    getScheme: () => dispatch(getScheme()),
    setSchemeFilter: (text) => dispatch(setSchemeFilter(text)),
    setSelectedScheme: (scheme) => dispatch(setSelectedScheme(scheme))
});

export default connect(mapStateToProps, mapDispatchToProps)(SchemePage)