import { REQUEST_QUERY, RECEIVE_QUERY, SET_EDITOR_VALUE, TOGGLE_SCROLL } from "../actions";

const defaultState = {
    result: null,
    query: '',
    value: '',
    isFetching: false,
    scrollEnabled: false,
}

const sqlEditor = (state = defaultState, action) => {
    switch (action.type) {
        case SET_EDITOR_VALUE:
            return {
                ...state,
                value: action.value
            }
        case REQUEST_QUERY:
            return {
                ...state,
                query: action.query,
                isFetching: true
            }
        case RECEIVE_QUERY:
            return {
                ...state,
                isFetching: false,
                result: action.result,
                message: action.message
            }
        case TOGGLE_SCROLL:
            return {
                ...state,
                scrollEnabled: !state.scrollEnabled
            }
        case 'LOGOUT':
            return defaultState;
        default:
            return state
    }
}

export default sqlEditor