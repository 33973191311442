import { INVALID_CREDENTIALS, RECEIVE_DATABASES, RECEIVE_TOKEN, SET_AUTH_FETCHING } from "../actions/auth";

export default (state = { isFetching: true }, action) => {
    switch (action.type) {
        case SET_AUTH_FETCHING:
            return {
                ...state,
                isFetching: true
            }
        case RECEIVE_DATABASES:
            return {
                ...state,
                isFetching: false,
                databases: action.data
            }
        case RECEIVE_TOKEN:
            return {
                ...state,
                isFetching: false,
                ...action.auth,
            };
        case INVALID_CREDENTIALS:
            return {
                ...state,
                isFetching: false,
                invalid: true
            }
        case 'LOGOUT':
            return {
                ...state,
                user: null,
                database: null,
                token: null,
            };
        default:
            return state;
    }
};


