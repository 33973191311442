
import fetch from 'cross-fetch'
import { setToken, clearToken } from '../auth/token-storage';
import { getTables, getScheme } from '.';
import { apiUrl } from '../consts';
export const RECEIVE_DATABASES = 'RECEIVE_DATABASES'
export const RECEIVE_TOKEN = 'RECEIVE_TOKEN'
export const INVALID_CREDENTIALS = 'INVALID_CREDENTIALS'
export const SET_AUTH_FETCHING = 'SET_AUTH_FETCHING'


function receiveDatabases(data) {
    return {
        type: RECEIVE_DATABASES,
        data
    }
}

export function refresh() {
    return dispatch => {
        dispatch(getTables());
        dispatch(getScheme());
    }
}

export function receiveToken(auth) {
    return dispatch => {
        dispatch({
            type: RECEIVE_TOKEN,
            auth
        })
        dispatch(refresh());
    }
}

export function setAuthFetching() {
    return {
        type: SET_AUTH_FETCHING,
    }
}


export function getDatabases() {
    return dispatch => {
        dispatch(setAuthFetching());
        return fetch(`${apiUrl}/api/v1/databases`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then(json => dispatch(receiveDatabases(json)))
    }
}

export function login(databaseName, userLogin, password) {
    return dispatch => {
        dispatch(setAuthFetching());
        return fetch(`${apiUrl}/api/v1/token`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                databaseName: databaseName,
                userLogin: userLogin,
                password: password
            })
        })
            .then(response => {
                if (response.status === 401) {
                    return dispatch({ type: INVALID_CREDENTIALS })
                }
                return response.json()
            })
            .then(response => {
                const auth = { token: response.token, user: response.user, database: response.database }
                setToken(auth);
                dispatch(receiveToken(auth));
            })
    }
}

export const logout = () => {
    clearToken();
    return {
        type: 'LOGOUT'
    }
}; 
