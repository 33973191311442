
export default (schemas, text) => {
  return schemas.map(schema => {
    schema = { ...schema }
    schema.objects = schema.objects.filter(table => {
      const tableNameMatch = table.name.toLowerCase().includes(text.toLowerCase());
      return tableNameMatch
    })
    return schema;
  })
}


export const selectHints = (tables) =>
  tables
    .reduce((x, y) => x.concat(y), [])
    .reduce((obj, item) => {
      obj[item.fullName] = item.columns.map(x => x.name)
      return obj
    }, {})
